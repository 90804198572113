import Home from '../../pages/Home/Home';
import MicroTrainings from '../../pages/MicroTrainings/MicroTrainings';
import Broadcast from '../../pages/Broadcast/Broadcast';
import Evaluate from '../../pages/Evaluate/Evaluate';
import Learners from '../../pages/Learners/Learner';
import Login from '../../pages/Login/Login';
import Form from '../../pages/Form/Form';
import Contact from '../../pages/Contact/Contact';
import Profile from '../../pages/Profile/Profile';
import CompaignInvitation from '../../pages/CompaignInvitation/CompaignInvitation';
import ForgotPassword from '../../pages/ResetPassword/ForgotPassword';
import AdminPanel from '../../pages/Admin/AdminPanel';
import FormToPass from '../../pages/FormToPass/FormToPass';
import Links from '../../pages/Links/Links';
import Files from '../../pages/Files/Files';
import FormCompleted from '../../pages/FormToPass/FormCompleted';
import Welcome from '../../pages/Welcome/Welcome';
import Thanks from '../../pages/Welcome/Thanks';
import MicroTrainingsAnswer from '../../pages/MicroTrainings/MicroTrainingsAnswer';
import TrainingAnswerSubmitted from '../../pages/MicroTrainings/TrainingAnswerSubmitted';
import Success from '../../pages/Success/Success';
import EvaluateDetails from '../../pages/Evaluate/components/EvaluateDetails';
import EvaluateTrainingStatistics from '../../pages/Evaluate/components/EvaluateTrainingStatistics';
import RestorePassword from '../../pages/RestorePassword/RestorePassword';
import SuccessPassword from '../../pages/RestorePassword/SuccessPassword';
import SuccessPhoneSent from '../../pages/ResetPassword/SuccessPhoneSent';
import FormPreview from '../../pages/Form/components/FormPreview';
import NotFound from '../../pages/NotFound/NotFound';
import CreditsHistory from '../../pages/CreditsHistory/CreditsHistory';
import WebPageUser from '../../pages/MicroTrainings/components/WebPage/WebPageUser';
import WebPageSuccess from '../../pages/MicroTrainings/components/WebPage/WebPageSuccess';
import WebPageFail from '../../pages/MicroTrainings/components/WebPage/WebPageFail';
import WebPageInfo from '../../pages/MicroTrainings/components/WebPage/WebPageInfo';

const routes = {
  HOME: { path: '/', label: 'Accueil', isOnNavbar: false },
  CREATE: { path: '/create', label: 'Créer', isOnNavbar: true },
  BROADCAST: { path: '/diffuser', label: 'Diffuser', isOnNavbar: true },
  EVALUATE: { path: '/evaluate', label: 'Évaluer', isOnNavbar: true },
  EVALUATE_DETAILS: {
    path: '/evaluate-details/:id',
    label: 'Évaluer',
    isOnNavbar: false,
  },
  EVALUATE_TRAININGS_STATISTICS: {
    path: '/evaluate-statistics',
    label: 'Évaluer statistics',
    isOnNavbar: false,
  },
  LEARNERS: { path: '/learners', label: 'Nos apprenants', isOnNavbar: true },
  ADMINPANEL: {
    path: '/adminpanel',
    label: "Panneau d'administration",
    isOnNavbar: true,
  },
  COMPAIGN_INVITATION_OLD: {
    path: '/compaigninvitation',
    label: 'Invitation',
    isOnNavbar: false,
  },
  COMPAIGN_INVITATION_DETAILS_OLD: {
    path: '/compaigninvitation/:id',
    label: 'Invitation',
    isOnNavbar: false,
  },
  COMPAIGN_INVITATION: {
    path: '/b',
    label: 'Invitation',
    isOnNavbar: false,
  },
  COMPAIGN_INVITATION_DETAILS: {
    path: '/b/:id',
    label: 'Invitation',
    isOnNavbar: false,
  },
  MENU_INVITATION_DETAILS: {
    path: '/menu-invitation/:id',
    label: 'Menu invitation',
    isOnNavbar: false,
  },

  PROFILE: { path: '/profile', label: 'Profile', isOnNavbar: false },
  LOGIN: { path: '/login', label: 'Login', isOnNavbar: false },
  CONTACT: { path: '/contact', label: 'Contact', isOnNavbar: false },
  FORGOTPASSWORD: {
    path: '/forgotpassword',
    label: 'ForgotPassword',
    isOnNavbar: false,
  },

  SUCCESS_PHONE_SENT: {
    path: '/phone-success-sent',
    label: 'Success',
    isOnNavbar: false,
  },

  RESTORE_PASSWORD: {
    path: '/reset_password/:secret',
    label: 'Reset password',
    isOnNavbar: false,
  },

  SUCCESS_PASSWORD: {
    path: '/success-password',
    label: 'Success',
    isOnNavbar: false,
  },

  FORM: { path: '/form', label: 'Formulaire', isOnNavbar: true },
  FORM_TO_PASS: {
    path: '/g/:id',
    label: 'Form to pass',
    isOnNavbar: false,
  },
  FORM_COMPLETED: {
    path: '/form-completed',
    label: 'Form Completed',
    isOnNavbar: false,
  },
  FORM_PREVIEW: {
    path: '/form-preview',
    label: 'Form Preview',
    isOnNavbar: false,
  },

  LINKS: { path: '/l/:id', label: 'Links', isOnNavbar: false },
  FILES: { path: '/f/:id', label: 'Files', isOnNavbar: false },

  WELCOME: {
    path: '/p/:id',
    label: 'Welcome',
    isOnNavbar: false,
  },
  THANKS: {
    path: '/thanks',
    label: 'Thanks',
    isOnNavbar: false,
  },
  SUCCESS: {
    path: '/success',
    label: 'Success',
    isOnNavbar: false,
  },

  TRAINING_ANSWER: {
    path: '/q/:identifier',
    label: 'Training Answer',
    isOnNavbar: false,
  },

  TRAINING_ANSWER_OLD: {
    path: '/mq/:identifier',
    label: 'Training Answer',
    isOnNavbar: false,
  },

  TRAININGANS_SUBMITTED: {
    path: '/answer_submitted',
    label: 'Answer Submitted',
    isOnNavbar: false,
  },

  TRAINING_WEB_PAGE_PASS: {
    path: '/w/:id',
    label: 'Réponse Web',
    isOnNavbar: false,
  },

  TRAINING_WEB_PAGE_SUCCESS: {
    path: '/w/:id/success',
    label: 'Réponse Web success',
    isOnNavbar: false,
  },

  TRAINING_WEB_PAGE_FAIL: {
    path: '/w/:id/fail',
    label: 'Réponse eronnée',
    isOnNavbar: false,
  },

  TRAINING_WEB_PAGE_INFO: {
    path: '/w/:id/info',
    label: 'Texte après réponse',
    isOnNavbar: false,
  },

  CREDITS_HISTORY: {
    path: '/credits-history',
    label: 'Historique des crédits',
    isOnNavbar: true,
  },

  // MENU_TRAININGS: {
  //   path: '/menu',
  //   label: 'Menu',
  //   isOnNavbar: true,
  // },

  NOT_FOUND: {
    path: '*',
    label: 'Error',
    isOnNavbar: false,
  },
};

// No side-menu, no header (except logo)
export const cleanPages = [
  routes.FORM_PREVIEW.path,
  routes.COMPAIGN_INVITATION.path,
  routes.COMPAIGN_INVITATION_DETAILS.path,
  // routes.COMPAIGN_INVITATION_OLD.path,
  // routes.COMPAIGN_INVITATION_DETAILS_OLD.path,
  routes.LINKS.path,
  routes.FILES.path,
  routes.FORM_TO_PASS.path,
  routes.WELCOME.path,
  routes.TRAINING_ANSWER.path,
  routes.TRAINING_ANSWER_OLD.path,
  routes.TRAININGANS_SUBMITTED.path,
  routes.TRAINING_WEB_PAGE_PASS.path,
  routes.TRAINING_WEB_PAGE_SUCCESS.path,
  routes.TRAINING_WEB_PAGE_FAIL.path,
  routes.TRAINING_WEB_PAGE_INFO.path,
  routes.FORM_COMPLETED.path,
  routes.SUCCESS_PHONE_SENT.path,
  routes.RESTORE_PASSWORD.path,
  routes.SUCCESS_PASSWORD.path,
  routes.FORGOTPASSWORD.path,
  routes.SUCCESS.path,
].map((str) => str.replace(/:[^\/]+/g, ''));

export const noHeaderPages = ['/', routes.LOGIN.path];

export const globalRoutes = [
  {
    path: routes.FORM_COMPLETED.path,
    label: routes.FORM_COMPLETED.label,
    isOnNavbar: routes.FORM_COMPLETED.isOnNavbar,
    component: FormCompleted,
  },
  {
    path: routes.FORM_TO_PASS.path,
    label: routes.FORM_TO_PASS.label,
    isOnNavbar: routes.FORM_TO_PASS.isOnNavbar,
    component: FormToPass,
  },
  {
    path: routes.COMPAIGN_INVITATION_DETAILS.path,
    label: routes.COMPAIGN_INVITATION_DETAILS.label,
    isOnNavbar: routes.COMPAIGN_INVITATION_DETAILS.isOnNavbar,
    component: CompaignInvitation,
  },
  // {
  //   path: routes.COMPAIGN_INVITATION_DETAILS_OLD.path,
  //   label: routes.COMPAIGN_INVITATION_DETAILS_OLD.label,
  //   isOnNavbar: routes.COMPAIGN_INVITATION_DETAILS_OLD.isOnNavbar,
  //   component: CompaignInvitation,
  // },
  {
    path: routes.LINKS.path,
    label: routes.LINKS.label,
    isOnNavbar: routes.LINKS.isOnNavbar,
    component: Links,
  },
  {
    path: routes.RESTORE_PASSWORD.path,
    label: routes.RESTORE_PASSWORD.label,
    isOnNavbar: routes.RESTORE_PASSWORD.isOnNavbar,
    component: RestorePassword,
  },
  {
    path: routes.FILES.path,
    label: routes.FILES.label,
    isOnNavbar: routes.FILES.isOnNavbar,
    component: Files,
  },
  {
    path: routes.WELCOME.path,
    label: routes.WELCOME.label,
    isOnNavbar: routes.WELCOME.isOnNavbar,
    component: Welcome,
  },
  {
    path: routes.THANKS.path,
    label: routes.THANKS.label,
    isOnNavbar: routes.THANKS.isOnNavbar,
    component: Thanks,
  },
  {
    path: routes.SUCCESS.path,
    label: routes.SUCCESS.label,
    isOnNavbar: routes.SUCCESS.isOnNavbar,
    component: Success,
  },
  {
    path: routes.SUCCESS_PASSWORD.path,
    label: routes.SUCCESS_PASSWORD.label,
    isOnNavbar: routes.SUCCESS_PASSWORD.isOnNavbar,
    component: SuccessPassword,
  },
  {
    path: routes.SUCCESS_PHONE_SENT.path,
    label: routes.SUCCESS_PHONE_SENT.label,
    isOnNavbar: routes.SUCCESS_PHONE_SENT.isOnNavbar,
    component: SuccessPhoneSent,
  },
  {
    path: routes.TRAINING_ANSWER.path,
    label: routes.TRAINING_ANSWER.label,
    isOnNavbar: routes.TRAINING_ANSWER.isOnNavbar,
    component: MicroTrainingsAnswer,
  },
  {
    path: routes.TRAINING_ANSWER_OLD.path,
    label: routes.TRAINING_ANSWER_OLD.label,
    isOnNavbar: routes.TRAINING_ANSWER_OLD.isOnNavbar,
    component: MicroTrainingsAnswer,
  },
  {
    path: routes.TRAININGANS_SUBMITTED.path,
    label: routes.TRAININGANS_SUBMITTED.label,
    isOnNavbar: routes.TRAININGANS_SUBMITTED.isOnNavbar,
    component: TrainingAnswerSubmitted,
  },
  {
    path: routes.TRAINING_WEB_PAGE_PASS.path,
    label: routes.TRAINING_WEB_PAGE_PASS.label,
    isOnNavbar: routes.TRAINING_WEB_PAGE_PASS.isOnNavbar,
    component: WebPageUser,
  },
  {
    path: routes.TRAINING_WEB_PAGE_SUCCESS.path,
    label: routes.TRAINING_WEB_PAGE_SUCCESS.label,
    isOnNavbar: routes.TRAINING_WEB_PAGE_SUCCESS.isOnNavbar,
    component: WebPageSuccess,
  },
  {
    path: routes.TRAINING_WEB_PAGE_FAIL.path,
    label: routes.TRAINING_WEB_PAGE_FAIL.label,
    isOnNavbar: routes.TRAINING_WEB_PAGE_FAIL.isOnNavbar,
    component: WebPageFail,
  },
  {
    path: routes.TRAINING_WEB_PAGE_INFO.path,
    label: routes.TRAINING_WEB_PAGE_INFO.label,
    isOnNavbar: routes.TRAINING_WEB_PAGE_INFO.isOnNavbar,
    component: WebPageInfo,
  },
];

export const unauthorizedRoutes = [
  {
    path: routes.LOGIN.path,
    label: routes.LOGIN.label,
    isOnNavbar: routes.LOGIN.isOnNavbar,
    component: Login,
  },
  {
    path: routes.CONTACT.path,
    label: routes.CONTACT.label,
    isOnNavbar: routes.CONTACT.isOnNavbar,
    component: Contact,
  },
  {
    path: routes.FORGOTPASSWORD.path,
    label: routes.FORGOTPASSWORD.label,
    isOnNavbar: routes.FORGOTPASSWORD.isOnNavbar,
    component: ForgotPassword,
  },
  {
    path: routes.HOME.path,
    label: routes.LOGIN.label,
    isOnNavbar: routes.LOGIN.isOnNavbar,
    component: Login,
  },
];

export const clientRoutes = [
  {
    path: routes.HOME.path,
    label: routes.HOME.label,
    isOnNavbar: routes.HOME.isOnNavbar,
    component: Home,
  },
  {
    path: routes.FORM.path,
    label: routes.FORM.label,
    isOnNavbar: routes.FORM.isOnNavbar,
    component: Form,
  },
  {
    path: routes.FORM_PREVIEW.path,
    label: routes.FORM_PREVIEW.label,
    isOnNavbar: routes.FORM_PREVIEW.isOnNavbar,
    component: FormPreview,
  },
  {
    path: routes.CREATE.path,
    label: routes.CREATE.label,
    isOnNavbar: routes.CREATE.isOnNavbar,
    component: MicroTrainings,
  },
  {
    path: routes.BROADCAST.path,
    label: routes.BROADCAST.label,
    isOnNavbar: routes.BROADCAST.isOnNavbar,
    component: Broadcast,
  },
  {
    path: routes.EVALUATE.path,
    label: routes.EVALUATE.label,
    isOnNavbar: routes.EVALUATE.isOnNavbar,
    component: Evaluate,
  },
  {
    path: routes.EVALUATE_DETAILS.path,
    label: routes.EVALUATE_DETAILS.label,
    isOnNavbar: routes.EVALUATE_DETAILS.isOnNavbar,
    component: EvaluateDetails,
  },
  {
    path: routes.EVALUATE_TRAININGS_STATISTICS.path,
    label: routes.EVALUATE_TRAININGS_STATISTICS.label,
    isOnNavbar: routes.EVALUATE_TRAININGS_STATISTICS.isOnNavbar,
    component: EvaluateTrainingStatistics,
  },
  {
    path: routes.LEARNERS.path,
    label: routes.LEARNERS.label,
    isOnNavbar: routes.LEARNERS.isOnNavbar,
    component: Learners,
  },
  {
    path: routes.PROFILE.path,
    label: routes.PROFILE.label,
    isOnNavbar: routes.PROFILE.isOnNavbar,
    component: Profile,
  },
  {
    path: routes.CREDITS_HISTORY.path,
    label: routes.CREDITS_HISTORY.label,
    isOnNavbar: routes.CREDITS_HISTORY.isOnNavbar,
    component: CreditsHistory,
  },
  {
    path: routes.NOT_FOUND.path,
    label: routes.NOT_FOUND.label,
    isOnNavbar: routes.NOT_FOUND.isOnNavbar,
    component: NotFound,
  },
];

export const adminRoutes = [
  ...clientRoutes,
  {
    path: routes.ADMINPANEL.path,
    label: routes.ADMINPANEL.label,
    isOnNavbar: routes.ADMINPANEL.isOnNavbar,
    component: AdminPanel,
  },
];

export const profileRoutes = [
  {
    path: routes.PROFILE.path,
    label: routes.PROFILE.label,
    isOnNavbar: routes.PROFILE.isOnNavbar,
    component: Profile,
  },
];

export const getNavbarRoutes = (role) => {
  if (role === 'admin') {
    return adminRoutes.filter((route) => route.isOnNavbar);
  } else {
    return clientRoutes.filter((route) => route.isOnNavbar);
  }
};

export const getNavbarRoutesProfiles = (role) => {
  let routes;

  routes = !role ? unauthorizedRoutes : profileRoutes;

  const availableRoutes = [];

  for (const route of routes) {
    availableRoutes.push(route);
  }

  return availableRoutes;
};
