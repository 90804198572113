import React, { useEffect, useRef, useState } from 'react';
import EditButton from './EditButton';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers';
import useStore from '../../../store/store';
import EmojiePickerWrapper from '../../MicroTrainings/components/EmojiePickerWrapper';
import ContentLength from '../../MicroTrainings/components/ContentLength';

import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';
import {
  diffuserActionTypes,
  diffuserDateTypes,
  diffuserTypes,
  reminderLength,
} from '../../../constants/broadcast';

const Reminder = ({
  tableData,
  setTableData,
  index,
  row,
  isActive,
  copyTableToCompare,
  diffuserType,
  areActionBtnsDisabled = false,
}) => {
  const textFieldRef = useRef(null);
  const emojiPickerRef = useRef(null);
  const emojieToggleButton = useRef(null);

  const [isFixed, setIsFixed] = useState(false);
  const [originalPosition, setOriginalPosition] = useState({ x: 0, y: 0 });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const { setSnackbarContent } = useStore((store) => store);

  const [tempReminderContent, setReminderContent] = useState(() => {
    return {
      content: tableData[index]?.reminder_content?.content || '',
      date:
        dayjs(tableData[index]?.reminder_content?.date) ||
        dayjs(tableData[index]?.date).add(4, 'hour') ||
        dayjs().add(4, 'hour'),
    };
  });

  const openReminderModal = () => {
    setIsModalOpen(true);
  };

  const closeReminderModal = () => {
    setIsModalOpen(false);
  };

  const changeReminderText = (e) => {
    setReminderContent((prev) => ({ ...prev, content: e.target.value }));
  };

  const changeReminderDate = (date) => {
    setReminderContent((prev) => ({ ...prev, date: date }));
  };

  const handleSubmit = () => {
    closeReminderModal();

    const updatedTable = [...tableData];

    if (!updatedTable[index].reminder_content) {
      updatedTable[index].reminder_content = {
        content: '',
        date: tableData[index]?.date.add(4, 'hour') || dayjs().add(4, 'hour'),
        date: tableData[index]?.date.add(4, 'hour') || dayjs().add(4, 'hour'),
      };
    }

    updatedTable[index].reminder = true;
    updatedTable[index].reminder_content.content = tempReminderContent.content;
    updatedTable[index].reminder_content.date = tempReminderContent.date;

    setTableData(updatedTable);
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  useEffect(() => {
    if (showEmojiPicker && !isFixed) {
      const element = emojieToggleButton.current;
      const rect = element.getBoundingClientRect();

      const emojieBody = emojiPickerRef.current.getBoundingClientRect();
      const gap = 10;

      const coord = {
        x: rect.right - emojieBody.width * 2 - gap,
        y: rect.top + 30,
      };

      setOriginalPosition(coord);

      setIsFixed(true);
    }
  }, [showEmojiPicker, isFixed]);

  const handleEmojiSelect = (emoji) => {
    const characterCount = tempReminderContent.content?.length || 0;

    if (characterCount + 1 > reminderLength) {
      setSnackbarContent('error', "Il n'y a pas d'espace vide");
      setShowEmojiPicker(false);
      focusTextField();
      return;
    }

    if (textFieldRef.current) {
      const input = textFieldRef.current;
      const cursorPosition = input.selectionStart;
      const currentValue = tempReminderContent.content;
      const newValue =
        currentValue.substring(0, cursorPosition) +
        emoji.emoji +
        currentValue.substring(cursorPosition);

      setReminderContent((prev) => ({ ...prev, content: newValue }));

      setShowEmojiPicker(false);

      setTimeout(() => {
        input.focus();
        const newCursorPosition = cursorPosition + 2;
        input.setSelectionRange(newCursorPosition, newCursorPosition);
      }, 500);
    } else {
      setReminderContent((prev) => ({
        ...prev,
        content: prev.content + emoji.emoji,
      }));

      setShowEmojiPicker(false);
      focusTextField();
    }
  };

  const focusTextField = () => {
    setTimeout(() => {
      textFieldRef.current.focus();
      textFieldRef.current.setSelectionRange(
        textFieldRef.current.value.length,
        textFieldRef.current.value.length
      );
    }, 500);
  };

  const handleDaysChange = (event, row, index, type) => {
    const time = +event.target.value;

    if (typeof time === 'number' && !isNaN(time)) {
      const updatedTable = tableData.map((item, i) => {
        if (i === index) {
          if (index === 0 || index === 1) {
            const date =
              type === 'days'
                ? row.date
                    .add(time, 'day')
                    .add(row.reminder_content.stepsHours, 'hour')
                : row.date
                    .add(time, 'hour')
                    .add(row.reminder_content.stepsDays, 'day');
            setReminderContent((prev) => ({ ...prev, date: date }));
            return {
              ...item,
              reminder_content: {
                ...item.reminder_content,
                stepsHours:
                  type === 'hours' ? time : row.reminder_content.stepsHours,
                stepsDays:
                  type === 'days' ? time : row.reminder_content.stepsDays,
                content:
                  tempReminderContent.content ||
                  tableData[index]?.reminder_content?.content,
                date: date,
              },
            };
          }

          // If we enter number in Days input -> we add days and minutes.
          // Same for Hours input. We should always add both.
          const date =
            type === 'days'
              ? row.date
                  .add(time, 'day')
                  .add(row.reminder_content.stepsHours, 'hour')
              : row.date
                  .add(row.reminder_content.stepsDays, 'days')
                  .add(time, 'hour');

          setReminderContent((prev) => ({ ...prev, date: date }));
          return {
            ...item,
            reminder_content: {
              ...item.reminder_content,
              stepsHours:
                type === 'hours' ? time : item.reminder_content.stepsHours,
              stepsDays:
                type === 'days' ? time : item.reminder_content.stepsDays,
              content:
                tempReminderContent.content ||
                tableData[index]?.reminder_content?.content,
              date: date,
            },
          };
        }

        if (i > index) {
          return {
            ...item,
            reminder_content: {
              ...item.reminder_content,
            },
          };
        }

        return item;
      });

      setTableData(updatedTable);
    }
  };

  const clearReminder = () => {
    const updatedTable = [...tableData];

    updatedTable[index].reminder = false;

    updatedTable[index].reminder_content = {
      content: '',
      stepsDays: 0,
      stepsHours: 0,
      date: updatedTable[index].date
        ? updatedTable[index].date.add(4, 'hour')
        : dayjs().add(4, 'hour'),
    };

    setTableData(updatedTable);
    setIsModalOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target) &&
        !emojiPickerRef.current.classList.contains('.emojie-picker-wrapper')
      ) {
        setShowEmojiPicker(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isModalOpen || tableData) {
      setReminderContent({
        content: tableData[index].reminder_content?.content || '',
        date:
          dayjs(tableData[index].reminder_content?.date) ||
          dayjs().add(4, 'hour'),
      });
    }
  }, [isModalOpen, tableData]);

  const isError =
    diffuserType !== diffuserTypes.manual.value &&
    ((row.reminder && copyTableToCompare[index]?.reminder_content?.isError) ||
      (diffuserType === diffuserTypes.specific.value && row.reminder && copyTableToCompare[index]?.isError));

  const maxDateDatePicker =
    diffuserType === diffuserTypes.specific.value && tableData[index + 1]
      ? tableData[index + 1].date.subtract(1, 'minute')
      : diffuserType === diffuserTypes.timing.value &&
        tableData?.[index + 1]?.step
      ? dayjs(row.date)
          .add(tableData[index + 1].step, 'day')
          .subtract(1, 'minute')
      : undefined;

  return (
    <>
      <EditButton
        handleOpen={!areActionBtnsDisabled && openReminderModal}
        tooltipText={
          diffuserType === diffuserTypes.manual.value
            ? 'Si vous activez la relance, le contenu sera diffusé 4h plus tard'
            : diffuserType === diffuserTypes.timing.value
            ? 'Relance Training Continu'
            : 'Relance'
        }
        icon={
          <NotificationsActiveRoundedIcon
            sx={{
              width: '25px',
              height: '25px',
              color: isError
                ? '#d32f2f'
                : row.reminder
                ? '#66DEAE'
                : areActionBtnsDisabled
                ? '#dedede'
                : '#9f9f9f',
            }}
          />
        }
      />

      <Dialog open={isModalOpen} maxWidth="sm" fullWidth scroll={'paper'}>
        <DialogContent>
          <Box
            display={'flex'}
            justifyContent={'center'}
            flexDirection={'column'}
            marginTop={'30px'}
            gap={5}
          >
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <Box sx={{ width: '100%' }}>
                <TextField
                  label="Contenu"
                  autoComplete="off"
                  variant="outlined"
                  multiline
                  minRows={3}
                  sx={{ width: '100%' }}
                  value={tempReminderContent.content}
                  onChange={changeReminderText}
                  inputRef={textFieldRef}
                  error={tempReminderContent.content.length > reminderLength}
                />
                <ContentLength
                  characterCount={tempReminderContent.content?.length || 0}
                  limitter={reminderLength}
                  margin="10px 5px 0 0"
                />
              </Box>
              <Button
                ref={emojieToggleButton}
                variant="contained"
                color="primary"
                disabled={showEmojiPicker}
                onClick={toggleEmojiPicker}
                sx={{
                  borderRadius: '50%',
                  padding: '0',
                  minWidth: '30px',
                  minHeight: '30px',
                  marginLeft: '10px',
                  transition: '0.3s',
                }}
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 64 64"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  className="iconify iconify--emojione-monotone"
                  fill="white"
                >
                  <path d="M59.998 21.223a12.826 12.826 0 0 1-.006-.402v-.527c0-.41-.045-.492-.263-.604-.156-.08-.316-.154-.478-.229C54.504 9.156 44.09 2 32 2S9.497 9.156 4.748 19.461c-.161.074-.321.148-.477.229-.219.111-.262.193-.262.604v.527c0 .178-.003.301-.006.404A29.89 29.89 0 0 0 2 32c0 16.568 13.432 30 30 30s30-13.432 30-30a29.93 29.93 0 0 0-2.002-10.777M32 4.5c10.278 0 19.252 5.672 23.971 14.047-3.744-.625-8.794-.586-11.467-.354-3.436.303-6.307 1.076-8.656 2.279-2.198 1.098-5.497 1.098-7.697 0-2.349-1.203-5.22-1.977-8.654-2.279-2.673-.232-7.722-.271-11.467.354C12.748 10.172 21.722 4.5 32 4.5m0 55C16.836 59.5 4.5 47.164 4.5 32c0-3.041.504-5.967 1.42-8.705.596 1.066.998 2.553 1.259 4.346.598 4.213 2.666 6.854 6.022 8.115a13.471 13.471 0 0 0 9.69-.105c1.691-.676 3.176-1.742 4.355-3.477 2.067-3.037 1.448-4.936 2.516-7.547.932-2.277 3.541-2.277 4.473 0 1.067 2.611.448 4.51 2.516 7.547 1.179 1.734 2.664 2.801 4.354 3.477a13.474 13.474 0 0 0 9.691.105c3.356-1.262 5.424-3.902 6.022-8.115.262-1.793.663-3.281 1.26-4.346A27.38 27.38 0 0 1 59.498 32C59.5 47.164 47.164 59.5 32 59.5" />
                  <path d="M44.584 42.279c-8.109 5.656-17.105 5.623-25.168 0-.97-.678-1.845.494-1.187 1.578 2.457 4.047 7.417 7.649 13.771 7.649s11.314-3.604 13.771-7.649c.659-1.084-.216-2.253-1.187-1.578" />
                </svg>
              </Button>
            </Box>

            {showEmojiPicker && (
              <EmojiePickerWrapper
                emojiPickerRef={emojiPickerRef}
                handleEmojiSelect={handleEmojiSelect}
                originalPosition={originalPosition}
                isFixed={isFixed}
              />
            )}

            {(diffuserType === diffuserTypes.specific.value) && (
              <DateTimePicker
                className="full-width"
                ampm={false}
                label="Date"
                timeSteps={{ hours: 1, minutes: 1 }}
                value={tempReminderContent.date}
                onChange={changeReminderDate}
                disablePast={true}
                minDateTime={row.date.add(1, 'minute')}
                // maxDateTime={maxDateDatePicker}
              />
            )}

            {diffuserType === diffuserTypes.timing.value && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '10px',
                }}
              >
                <TextField
                  value={row.reminder_content.stepsDays}
                  fullWidth
                  label="Days"
                  onChange={(event) =>
                    handleDaysChange(event, row, index, 'days')
                  }
                />
                <TextField
                  value={row.reminder_content.stepsHours}
                  label="Hours"
                  fullWidth
                  onChange={(event) =>
                    handleDaysChange(event, row, index, 'hours')
                  }
                />
              </Box>
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Box
            display={'flex'}
            justifyContent={'center'}
            marginTop={'30px'}
            gap={1}
          >
            {/* <Button
              variant="outlined"
              color="error"
              onClick={closeReminderModal}
            >
              Annuler
            </Button> */}
            <Button variant="outlined" color="error" onClick={clearReminder}>
              Supprimer
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={
                tempReminderContent.content.length > reminderLength ||
                !tempReminderContent.content?.length ||
                0
              }
            >
              Enregistrer
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Reminder;
